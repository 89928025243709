<msep-page-title [forceBackgroundImageUse]="true">
  <h1>Partner Resources</h1>
  <h2>
    The resources on this page are collateral materials meant for reference by
    enduring and potential partners. These files will provide all the needed
    information for organizations to prepare for and participate in the MSEP
    program. If an item isn't covered in the resources below, please submit a
    Contact Us form with the details of your question or comment.
  </h2>
  <mat-card>
    <mat-card-content>
      <div class="flex">
        <form
          [formGroup]="form"
          class="global-search-form-fields flex flex-wrap flex-row grid-gap-16 justify-between align-center"
        >
          <msep-partner-resources-autocomplete
            class="flex-100 flex-gt-sm-50"
            [formGroup]="form"
            formControlName="title"
            (resourceSelected)="onTitleSelected($event)"
          ></msep-partner-resources-autocomplete>
          <mat-form-field class="flex-100 flex-gt-sm-50" appearance="outline">
            <mat-label>Select Resource Category</mat-label>
            <mat-select
              formControlName="categoryId"
              (selectionChange)="onCategoryChanged($event)"
            >
              <mat-option
                *ngFor="let category of categories$ | async"
                [value]="category.id"
              >
                {{ category.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="action-buttons flex-100">
            <button mat-raised-button type="reset" (click)="onReset()">
              Reset
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>

    <msep-card-loader [isLoading]="isLoading"></msep-card-loader>
  </mat-card>
</msep-page-title>

<div *ngIf="partnerResources$ | async" class="margin-top-20">
  <ng-container *ngIf="resultTotal > 0; else noResults">
    <div
      class="flex flex-col flex-wrap flex-gt-sm-row justify-between grid-gap-16"
    >
      <div
        class="margin-bottom-16 flex-100 flex-gt-sm-32 flex-gt-lg-19"
        *ngFor="let partnerResource of partnerResources"
      >
        <mat-card matRipple class="resource-card">
          <a role="link" [href]="partnerResource.url">
            <div class="image-container justify-center align-center">
              <mat-icon>{{ partnerResource.icon }}</mat-icon>
            </div>
            <div class="resource-card-info">
              <mat-card-title>{{ partnerResource.title }}</mat-card-title>
            </div>
          </a>
        </mat-card>
      </div>
    </div>
    <msep-pagination
      #paginator
      [pageSize]="pageSize"
      [length]="resultTotal"
      (pagingChange)="onPagingChange($event)"
    ></msep-pagination>
  </ng-container>
  <ng-template #noResults>
    <msep-no-results></msep-no-results>
  </ng-template>
</div>

<msep-ghost-loader-list
  class="row-in-column flex flex-wrap flex-row justify-between grid-gap-8 grid-gap-gt-sm-16"
  [type]="ghostLoaderType"
  [ghostLength]="pageSize"
  [childFlex]="100"
  [childFlexGtSm]="32"
  [childFlexGtLg]="19"
  *ngIf="!partnerResources"
></msep-ghost-loader-list>
