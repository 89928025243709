import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  public config!: Config;
  constructor(private http: HttpClient) {}

  async loadConfig(): Promise<void> {
    const config$ = this.http.get<Config>(
      `assets/config/${environment.configSettings}`
    );
    this.config = await lastValueFrom(config$);
  }
}

export function configProviderFactory(configService: ConfigService) {
  return () => configService.loadConfig();
}

export const CONFIG_SERVICE_PROVIDER = {
  provide: APP_INITIALIZER,
  useFactory: configProviderFactory,
  deps: [ConfigService],
  multi: true,
};

export interface Config {
  chatHubBaseUrl: string;
  googleAnalyticsKey: string;
  googleMapsApiKey: string;
  ingesterS3BucketUrl: string;
  msepPartnerApiBaseUrl: string;
  recaptchaSiteKey: string;
  refreshTokenStorageKey: string;
  resourcesS3BucketUrl: string;
  logosS3BucketUrl: string;
  sapApiBaseUrl: string;
  secoApiBaseUrl: string;
  tokenStorageKey: string;
  version: string;
}
