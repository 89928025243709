import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError, map } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import { ConfigService } from './infrastructure/config.service';

@Injectable({
  providedIn: 'root',
})
export class HelpDeskService {
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;
  private savedFilters: HttpParams | null = null;

  constructor(private http: HttpClient, private configService: ConfigService) {}

  clearSavedFilters(): void {
    this.savedFilters = null;
  }

  createHelpDeskTicket(
    form: ContactUsForm,
    reCaptchaResponse: string | undefined
  ): Observable<unknown> {
    const headers: Record<string, string> = {};

    if (reCaptchaResponse) {
      headers['g-recaptcha-response'] = reCaptchaResponse;
    }
    return this.http.post(`${this.msepPartnerApiUrl}/public/contact-us`, form, {
      headers,
    });
  }

  createAuthHelpDeskTicket(form: ContactUsAuthForm): Observable<unknown> {
    return this.http.post(
      `${this.msepPartnerApiUrl}/admin/contact-us/auth-ticket`,
      form
    );
  }

  getContactUsTicket(ticketId: number): Observable<ContactUsTicket> {
    return this.http
      .get<ApiResponse<ContactUsTicket>>(
        `${this.msepPartnerApiUrl}/admin/contact-us/${ticketId}`
      )
      .pipe(map((response) => response.data));
  }

  getContactUsTickets(
    params: HttpParams
  ): Observable<ApiResponse<ContactUsTicketList[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/contact-us/tickets`;
    return this.http
      .get<ApiResponse<ContactUsTicketList[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getSavedFilters(): HttpParams | null {
    return this.savedFilters;
  }

  saveFilters(filters: HttpParams): void {
    this.savedFilters = filters;
  }

  updateContactUsTicket(ticket: ContactUsTicket): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.msepPartnerApiUrl}/admin/contact-us`, ticket)
      .pipe((response) => {
        return response;
      }, catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface ContactUsTicketList {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  submitDate: Date;
  resolvedDate: Date | null;
  status: string;
  assignedTo: string | null;
  organizationId: number | null;
  organizationName: string | null;
}
export interface ContactUsForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isRegistered: boolean;
  contactUsAssistanceTypeId: number;
  contactUsContactWindowId: number;
  contactUsPreferredCommunicationId: number;
  contactUsPriorityLevelId?: number;
  message: string;
  organizationId?: number;
}

export interface ContactUsAuthForm {
  contactUsAssistanceTypeId: number;
  contactUsContactWindowId: number;
  contactUsPreferredCommunicationId: number;
  message: string;
}

export interface ContactUsTicket {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isRegistered: boolean;
  contactUsAssistanceTypeId: number;
  contactUsPriorityLevelId?: number;
  contactUsContactWindow: string;
  contactUsPreferredCommunication: string;
  organizationId?: number;
  organizationName: string;
  areaOfAssistance: string;
  message: string;
  assignedToUserId?: number;
  statusId: number;
  resolvedDate?: Date;
  ipAddress: string;
}
