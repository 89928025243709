import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { GhostLoaderType } from 'core/enums';
import { AuthenticationService } from 'core/services/authentication.service';
import {
  PartnerResource,
  PartnerResourceFilter,
  PartnersService,
} from 'core/services/partners.service';
import { Resource, ResourcesService } from 'core/services/resources.service';
import { BehaviorSubject, Observable, delay, switchMap, tap } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';

@Component({
  selector: 'msep-partner-resources',
  templateUrl: './partner-resources.component.html',
  styleUrls: ['./partner-resources.component.scss'],
})
export class PartnerResourcesComponent implements OnInit {
  @ViewChild('paginator', { static: false }) paginator:
    | MatPaginator
    | undefined;

  categories$ = this.resourceService.getResourceCategories();
  form!: UntypedFormGroup;
  ghostLoaderType = GhostLoaderType.PartnerLogo;
  isLoading = false;
  pageSize = 10;
  partnerResources: PartnerResource[] | undefined;
  partnerResourcesAction$!: Observable<PartnerResourceFilter>;
  partnerResources$!: Observable<ApiResponse<PartnerResource[]>>;
  resultTotal = 0;

  private defaultFilter = {
    skip: 0,
    take: this.pageSize,
  } as PartnerResourceFilter;
  private partnerResourcesSubject = new BehaviorSubject<PartnerResourceFilter>(
    this.defaultFilter
  );

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private partnersService: PartnersService,
    private resourceService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: null,
      title: null,
      categoryId: null,
    });

    this.setPartnerResourcesRequestStream();
  }

  isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  onCategoryChanged(event: MatSelectChange): void {
    this.form.patchValue({
      categoryId: event.value,
    });
    this.search();
  }

  onPagingChange(event: { skip: number; take: number }): void {
    const filter = this.form.value as PartnerResourceFilter;
    this.pageSize = event.take;
    filter.take = event.take;
    filter.skip = event.skip;
    this.partnerResourcesSubject.next(filter);
  }

  onReset(): void {
    this.form.reset();
    this.paginator?.firstPage();
    this.pageSize = this.defaultFilter.take;
    this.partnerResourcesSubject.next(this.defaultFilter);
  }

  onTitleSelected(title: Resource): void {
    this.form.patchValue({ id: title.id });
    this.search();
  }

  private search(): void {
    const filter = this.form.value as PartnerResourceFilter;
    filter.take = this.paginator?.pageSize ?? this.pageSize;
    filter.skip = 0;
    if (this.paginator !== undefined) {
      this.paginator.firstPage();
    }
    this.partnerResourcesSubject.next(filter);
  }

  private setPartnerResourcesRequestStream(): void {
    this.partnerResourcesAction$ = this.partnerResourcesSubject.asObservable();
    this.partnerResources$ = this.partnerResourcesAction$.pipe(
      delay(0),
      tap(() => (this.isLoading = true)),
      switchMap((filters: PartnerResourceFilter) =>
        this.partnersService.getPartnerResources(filters)
      ),
      tap((results) => {
        this.resultTotal = results.total;
        this.partnerResources = results.data;
        this.isLoading = false;
      })
    );
  }
}
