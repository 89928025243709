<msep-page-title>
  <h1>Need an account?</h1>
  <h2>
    MSEP looks forward to supporting your organization's effort to recruit,
    hire, promote and retain military spouses.
  </h2>
</msep-page-title>
<h2 class="text-center">Before You Get Started</h2>
<msep-alert>
  The account creation process is
  <strong><em>exclusively</em></strong> for organizations and their employees
  who wish to partner with the DoD MSEP Program. Please refer to the
  <a role="link" [routerLink]="['/become-a-partner']">latest guidance</a> about
  becoming an MSEP Partner, and carefully review the information below.
</msep-alert>
<div class="margin-top-20">
  <div class="flex flex-col flex-gt-sm-row gap-1rem">
    <mat-card class="choice flex flex-100 flex-gt-xs-50">
      <h3>Are you a military spouse?</h3>
      <p>
        The Spouse Education and Career Opportunities (MySECO) Portal provides
        education and career guidance to military spouses worldwide, offering
        comprehensive resources and tools related to career exploration,
        education, training and licensing, employment readiness and career
        connections. Call
        <strong>800-342-9647</strong> to speak with a certified career coach.
      </p>
      <div class="button-group">
        <a
          role="link"
          href="https://myseco.militaryonesource.mil/portal/getting-started"
          mat-raised-button
          color="primary"
          >Register for MySECO</a
        >
        <a
          role="link"
          href="https://myseco.militaryonesource.mil/portal/msep"
          mat-raised-button
          color="primary"
          >Search for Jobs</a
        >
      </div>
    </mat-card>
    <mat-card class="choice flex flex-100 flex-gt-xs-50">
      <h3>Are you representing an organization?</h3>
      <p>
        If your organization is interested in becoming a MSEP partner, or you
        are interested in gaining access to your organization's MSEP account,
        our Help Desk team is ready to assist you. Click below to get started!
      </p>
      <div class="button-group">
        <a
          role="link"
          mat-raised-button
          color="primary"
          [routerLink]="['/notice-and-consent', 'contact-us']"
        >
          Submit account request
        </a>
      </div>
    </mat-card>
  </div>
</div>
