<footer>
  <div class="msep-footer">
    <div class="msep-footer-logo">
      <p>
        <img
          src="assets/images/white-mos-msep-logo.png"
          width="125px"
          alt="MOS and MSEP Logo"
        />
      </p>
    </div>

    <div class="msep-footer-links">
      <p>
        <a role="link" [routerLink]="['/about-us']">About</a>
        |
        <a role="link" [routerLink]="['/partner-resources']">Resources</a> |
        <a role="link" [routerLink]="['/help']">Help</a> |
        <a role="link" [routerLink]="['/legal-and-security']"
          >Legal & Security</a
        >
        |
        <a role="link" [routerLink]="['/sitemap']">Sitemap</a>
      </p>
    </div>

    <div class="msep-footer-social">
      <div>
        <span>Military OneSource</span>
        <a
          role="link"
          class="social-icons"
          href="https://www.facebook.com/military.1source"
        >
          <mat-icon svgIcon="facebook" id="mosFacebook"></mat-icon>
          <span class="cdk-visually-hidden">
            Navigate to Military OneSource's Facebook page
          </span>
        </a>
        <a
          role="link"
          class="social-icons"
          href="https://twitter.com/Military1Source"
        >
          <mat-icon svgIcon="twitter" id="mosTwitter"></mat-icon>
          <span class="cdk-visually-hidden">
            Navigate to Military OneSource's Twitter page
          </span>
        </a>
        <a
          role="link"
          class="social-icons"
          href="http://pinterest.com/military1source/"
        >
          <mat-icon svgIcon="pinterest"></mat-icon>
          <span class="cdk-visually-hidden">
            Navigate to Military OneSource's Pinterest page
          </span>
        </a>
      </div>
      <div>
        <span>SECO</span>
        <a
          role="link"
          class="social-icons"
          href="https://www.facebook.com/DoDMilSpouse/?fref=ts"
        >
          <mat-icon svgIcon="facebook" id="secoFacebook"></mat-icon>
          <span class="cdk-visually-hidden">
            Navigate to SECO's Facebook page
          </span>
        </a>
        <a
          role="link"
          class="social-icons"
          href="https://twitter.com/DoDMilSpouse"
        >
          <mat-icon svgIcon="twitter" id="secoTwitter"></mat-icon>
          <span class="cdk-visually-hidden">
            Navigate to SECO's Twitter page
          </span>
        </a>
        <a
          role="link"
          class="social-icons"
          href="https://www.instagram.com/dodmilspouse/"
        >
          <mat-icon svgIcon="instagram"></mat-icon>
          <span class="cdk-visually-hidden">
            Navigate to SECO's Instagram page
          </span>
        </a>
      </div>
      <div>
        <span>MSEP</span>
        <a
          role="link"
          class="social-icons"
          href="https://www.linkedin.com/company/military-spouse-employment-partnership-msep-/"
        >
          <mat-icon svgIcon="linkedin"></mat-icon>
          <span class="cdk-visually-hidden">
            Navigate to MSEP's LinkedIn page
          </span>
        </a>
      </div>
    </div>
    <div class="msep-footer-disclaimer">
      <p><strong>OFFICIAL USE ONLY</strong></p>
      <p>
        ATTENTION: The information accessed and displayed through this system is
        FOR OFFICIAL USE ONLY and must be protected in accordance with the
        Privacy Act, DoDD 5400.11, and DoD 5400.11-R.
      </p>
      <p><strong>Version:</strong> {{ version }}</p>
    </div>
  </div>
</footer>
