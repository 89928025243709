import {
  AfterContentInit,
  Component,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from 'core/services/infrastructure/config.service';
import { JobFeedGuidanceService } from 'core/services/job-feed-guidance.service';
import { ResourcesService } from 'core/services/resources.service';
import { fadeIn, fadeOut } from 'core/utilities/animations';
import { fromEvent, map, Observable, startWith } from 'rxjs';
import { AlertType } from 'shared/components/alert/alert.component';

@Component({
  selector: 'msep-job-feed-guidance',
  templateUrl: './job-feed-guidance.component.html',
  styleUrls: ['./job-feed-guidance.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class JobFeedGuidanceComponent implements OnInit, AfterContentInit {
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;

  alertType = AlertType.Info;
  careerLevels$ = this.resourcesService.getCareerLevels();
  educationLevels$ = this.resourcesService.getEducationLevels();
  exampleCsvColumns = [
    'title',
    'description',
    'url',
    'jobIdentifier',
    'industry',
    'jobType',
    'compensationType',
    'hourlyRate',
    'minSalary',
    'maxSalary',
    'education',
    'yearsOfExperience',
    'career',
    'minQualifications',
    'prefQualifications',
    'address',
    'address2',
    'city',
    'state',
    'postal',
    'country',
    'isTelework',
    'isRemoteJob',
    'isHotJob',
    'jobOpenings',
    'tags',
  ];
  exampleCsv = this.jobFeedGuidanceService.getExampleCsv();
  exampleCsvUrl = `${this.msepPartnerApiUrl}/job-feed-guidance/sample.csv`;
  exampleJson = this.jobFeedGuidanceService.getExampleJson();
  exampleJsonUrl = `${this.msepPartnerApiUrl}/job-feed-guidance/sample.json`;
  exampleXml = this.jobFeedGuidanceService.getExampleXml();
  exampleXmlUrl = `${this.msepPartnerApiUrl}/job-feed-guidance/sample.xml`;
  fieldColumns = ['field', 'type', 'description'];
  header!: HTMLElement;
  headerIsVisible$!: Observable<boolean>;
  industries$ = this.resourcesService.getIndustrySectors();
  jobTypes$ = this.resourcesService.getJobTypes();
  navLinks = this.jobFeedGuidanceService.getNavLinks();
  requiredFields = this.jobFeedGuidanceService.getRequiredSpecs();
  urlPath = '/job-feed-guidance';
  yearsOfExperience$ = this.resourcesService.getYearsOfExperience();

  highlyRecommendedData = [
    {
      field: 'address',
      type: 'String',
      description: 'The street address associated with the job posting.',
    },
    {
      field: 'address2',
      type: 'String',
      description: 'Second line of address associated with the job posting.',
    },
    {
      field: 'city',
      type: 'String',
      description: 'The city in which the job will be performed.',
    },
    {
      field: 'state',
      type: 'String',
      description:
        'The state or province associated with the job posting. Please use the two-letter abbreviation for the state or province.',
    },
    {
      field: 'postal',
      type: 'String',
      description: `The postal code associated with the job posting. This may be either a USA formatted 5-digit
      zip code or an international postal code.`,
    },
    {
      field: 'country',
      type: 'String',
      description: `The country associated with the job posting. Valid standards compliant country
        names or ISO codes in the case of overseasjobs 3166-1 alpha-3 specifications
        <a role="link" href="https://www.nationsonline.org/oneworld/country_code_list.htm">
        https://www.nationsonline.org/oneworld/country_code_list.htm
        </a>.`,
    },
    {
      field: 'jobType',
      type: 'String',
      description: 'The type of job. You must use exact MSEP defined values:',
    },
    {
      field: 'industry',
      type: 'String',
      description:
        'The industry that this job is associated with and can be located under through advanced job searching. You must use exact MSEP defined values:',
    },
    {
      field: 'jid',
      type: 'String',
      description: `Unique identifier your company uses to track this job post internally.
      <em>
        No two jobs may have the same ID. HTML cannot be used in
        this field.
      </em>`,
    },
    {
      field: 'compensationType',
      type: 'String',
      description: `This field specifies whether the job posting is salaried or
      paid by the hour.
      <em>You must enter either 'Salary' or 'Hourly' exactly.</em>`,
    },
    {
      field: 'education',
      type: 'String',
      description:
        'This field specifies the required level of education for this job posting. You must use exact MSEP defined values:',
    },
    {
      field: 'minQualifications',
      type: 'String',
      description:
        'This field specifies minimum required qualifications for this job posting.',
    },
    {
      field: 'prefQualifications',
      type: 'String',
      description:
        'This field specifies preferred qualifications for this job posting.',
    },
    {
      field: 'hourlyRate',
      type: 'String',
      description: ` The hourly wage for this job posting. This field may be left
      blank if it is not applicable.
      <em>
        The field may only contain integers and/or a period. It
        should not contain any other punctuation, such as a dollar
        sign or comma.
      </em>`,
    },
    {
      field: 'minSalary',
      type: 'String',
      description: `The minimum salary for this job posting.
      <em>Example: "minSalary": "$80,000"</em>`,
    },
    {
      field: 'maxSalary',
      type: 'String',
      description: `The maximum salary for this job posting.
      <em>Example: "maxSalary": "$100,000"</em>`,
    },
    {
      field: 'yearsOfExperience',
      type: 'String',
      description:
        'Year(s) of knowledge or skill pertaining to this job posting. You must use exact MSEP defined values:',
    },
    {
      field: 'career',
      type: 'String',
      description:
        'Classification of leadership and authority. You must use exact MSEP defined values:',
    },
    {
      field: 'jobOpenings',
      type: 'String',
      description: `Total # of available positions.
      <em>Example: "jobOpenings": "1"</em>`,
    },
    {
      field: 'isTelework',
      type: 'Boolean',
      description: `Allows employees to carry out their duties and
      responsibilities from an offsite location other than the
      official workplace. It could involve working from home,
      another branch office, café, bookstore, or even a co-working
      space. Though often away from the office, a teleworker is
      different from a remote employee because there occasionally
      may be some in-person office attendance required.
      <em>
        You must enter either 'true' or 'false' exactly. Any job
        with blank isTelework is set to 'false' by default.
      </em>`,
    },
    {
      field: 'isRemoteJob',
      type: 'Boolean',
      description: `Also known as 'work from anywhere,' is a flexible way of
      working which allows employees to work from any remote
      location as long as they have stable internet connectivity.
      Remote employees typically don't reside near their main office
      but still work closely with the people within the
      organization.
      <em>
        You must enter either 'true' or 'false' exactly. Any job
        with blank isRemoteJob is set to 'false' by default.
      </em>`,
    },
    {
      field: 'isHotJob',
      type: 'Boolean',
      description: `This field specifies whether the job posting is a hot job.
      <em>
        You must enter either 'true' or 'false' exactly. Any job
        with blank isHotJob is set to 'false' by default.
      </em>`,
    },
    {
      field: 'tags',
      type: 'String',
      description: `Tags associated with the job posting.
      <em>
        Multiple terms must be separated by a comma and a space.
      </em>`,
    },
  ];

  constructor(
    private configService: ConfigService,
    private domSanitizer: DomSanitizer,
    private jobFeedGuidanceService: JobFeedGuidanceService,
    private resourcesService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.setJobTypeDescription();
    this.setIndustryDescription();
    this.setEducationDescription();
    this.setCareerDescription();
    this.setYearsOfExperienceDescription();
    this.requiredFields?.forEach((requiredField) => {
      requiredField.description =
        this.domSanitizer.sanitize(
          SecurityContext.HTML,
          requiredField.description
        ) ?? '';
    });
  }

  ngAfterContentInit(): void {
    this.header = <HTMLElement>document.getElementById('header');
    const scroll$ = fromEvent(document, 'scroll');

    this.headerIsVisible$ = scroll$.pipe(
      startWith(false),
      map(() => this.isHeaderVisible())
    );
  }

  onClick(): void {
    window.scrollTo(0, 0);
  }

  onClickExample(url: string): void {
    window.open(url, '_blank');
  }

  private isHeaderVisible(): boolean {
    return this.header.getBoundingClientRect().bottom > 0;
  }

  private setCareerDescription(): void {
    const education = this.highlyRecommendedData.find(
      (x) => x.field == 'career'
    );
    if (education) {
      education.description += '<ul>';
      this.careerLevels$.forEach((careerLevels) => {
        careerLevels.forEach((x) => {
          if (education != undefined)
            education.description += `<li> ${x.description} </li>`;
        });
        education.description += '</ul>';
      });
    }
  }

  private setEducationDescription(): void {
    const careerLevel = this.highlyRecommendedData.find(
      (x) => x.field == 'education'
    );
    if (careerLevel) {
      careerLevel.description += '<ul>';
      this.educationLevels$.forEach((educations) => {
        educations.forEach((x) => {
          if (careerLevel != undefined)
            careerLevel.description += `<li> ${x.description} </li>`;
        });
        careerLevel.description += '</ul>';
      });
    }
  }

  private setIndustryDescription(): void {
    const industry = this.highlyRecommendedData.find(
      (x) => x.field == 'industry'
    );
    if (industry) {
      industry.description += '<ul>';
      this.industries$.forEach((industries) => {
        industries.forEach((x) => {
          if (industry != undefined)
            industry.description += `<li> ${x.description} </li>`;
        });
        industry.description += '</ul>';
      });
    }
  }

  private setJobTypeDescription(): void {
    const jobType = this.highlyRecommendedData.find(
      (x) => x.field == 'jobType'
    );
    if (jobType) {
      jobType.description += '<ul>';
      this.jobTypes$.forEach((jobTypes) => {
        jobTypes.forEach((x) => {
          if (jobType != undefined)
            jobType.description += `<li> ${x.description} </li>`;
        });
        jobType.description +=
          '</ul><em>Multiple values must be separated by a comma and a space.</em>';
      });
    }
  }

  private setYearsOfExperienceDescription(): void {
    const yearsOfExperience = this.highlyRecommendedData.find(
      (x) => x.field == 'yearsOfExperience'
    );
    if (yearsOfExperience) {
      yearsOfExperience.description += '<ul>';
      this.yearsOfExperience$.forEach((yearsOfExperiences) => {
        yearsOfExperiences.forEach((x) => {
          if (yearsOfExperience != undefined)
            yearsOfExperience.description += `<li> ${x.description} </li>`;
        });
      });
    }
  }
}
