<mat-card class="dock">
  <mat-card-header>
    <mat-card-title>
      <div class="flex flex-row justify-between align-center">
        <div>
          <mat-icon *ngIf="icon" aria-hidden="true">{{ icon }}</mat-icon>
          {{ title }}
        </div>
        <mat-icon *ngIf="isCollapsed !== undefined" (click)="onClick()">{{
          isCollapsed ? 'add' : 'remove'
        }}</mat-icon>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="!isCollapsed">
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>
