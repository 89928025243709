import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Chart } from 'chart.js/auto';
import { LineChart } from 'core/enums';
import {
  LineChartMetrics,
  LineChartMetricsFilter,
  ChartsService,
} from 'core/services/charts.service';
import { Resource, ResourcesService } from 'core/services/resources.service';
import moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  delay,
  switchMap,
  tap,
} from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';

@Component({
  selector: 'msep-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() partnerId?: number;
  @Input() set lineChartId(value: LineChart) {
    this.lineChart = value;
    this.setChartValues();
    this.loadChartData();
  }

  get lineChartId(): LineChart {
    return this.lineChart;
  }

  lineChartMetrics!: LineChartMetrics[];
  lineChartMetrics$!: Observable<ApiResponse<LineChartMetrics[]>>;
  lineChartMetricsAction$!: Observable<LineChartMetricsFilter>;
  chart!: Chart;
  chartId!: string;
  chartTitle!: string;
  dateFormat = 'MM-dd-yyyy';
  form!: UntypedFormGroup;
  isLoading = false;
  partnershipTypes$ = this.resourceService.getPartnershipTypes();
  selectedEndDate!: Date;
  selectedStartDate!: Date;

  defaultFilter = {
    organizationId: undefined,
    partnershipTypeId: undefined,
    startDate: '',
    endDate: '',
  } as LineChartMetricsFilter;

  private lineChart!: LineChart;
  private lineChartMetricsSubject = new BehaviorSubject<LineChartMetricsFilter>(
    this.defaultFilter
  );
  private subscription = new Subscription();

  constructor(
    private chartsService: ChartsService,
    private formBuilder: UntypedFormBuilder,
    private resourceService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.defaultFilter.organizationId = this.partnerId;
    this.setChartValues();
    this.setDateFilterDefaults();
    this.buildForm();
    this.loadChartData();
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  get currentFilter(): LineChartMetricsFilter {
    return {
      ...this.form.value,
    };
  }

  onEndDateSelected(date: Date): void {
    const endDate = moment(date).toDate();
    endDate.setHours(0, 0, 0, 0);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(1);
    const selectedDate = new DatePipe('en-US').transform(
      endDate,
      this.dateFormat
    );

    if (!selectedDate) return;

    this.form.patchValue({
      endDate: selectedDate,
    });
    this.lineChartMetricsSubject.next(this.currentFilter);
  }

  onOrganizationSelected(organization: Resource): void {
    this.form.patchValue({
      organizationId: organization.id,
      organizationName: organization.description,
    });
    this.lineChartMetricsSubject.next(this.currentFilter);
  }

  onPartnershipTypeChanged(event: MatSelectChange): void {
    this.form.patchValue({
      partnershipTypeId: event.value,
    });
    this.lineChartMetricsSubject.next(this.currentFilter);
  }

  onStartDateSelected(date: Date): void {
    const startDate = moment(date).toDate();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(1);
    const selectedDate = new DatePipe('en-US').transform(
      startDate,
      this.dateFormat
    );

    if (!selectedDate) return;

    this.form.patchValue({
      startDate: selectedDate,
    });
    this.lineChartMetricsSubject.next(this.currentFilter);
  }

  onReset(): void {
    this.form.patchValue({
      organizationId: this.defaultFilter.organizationId,
      organizationName: null,
      partnershipTypeId: this.defaultFilter.partnershipTypeId,
      startDate: this.defaultFilter.startDate,
      endDate: this.defaultFilter.endDate,
    });
    this.setDateFilterDefaults();
    this.lineChartMetricsSubject.next(this.currentFilter);
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      organizationId: this.defaultFilter.organizationId,
      organizationName: null,
      partnershipTypeId: this.defaultFilter.partnershipTypeId,
      startDate: this.defaultFilter.startDate,
      endDate: this.defaultFilter.endDate,
    });
  }

  private createChart(): void {
    this.chart = new Chart(this.chartId, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 50,
            right: 50,
            bottom: 20,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

  private loadChartData(): void {
    this.lineChartMetricsAction$ = this.lineChartMetricsSubject.asObservable();

    this.lineChartMetrics$ = this.lineChartMetricsAction$.pipe(
      delay(0),
      tap(() => (this.isLoading = true)),
      switchMap((filters) => {
        switch (this.lineChart) {
          case LineChart.CandidateSearch:
            return this.chartsService.getCandidateSearchMetrics(
              filters,
              this.partnerId
            );
          case LineChart.SpousesHired:
            return this.chartsService.getSpouseHiredMetrics(
              filters,
              this.partnerId
            );
          case LineChart.TotalPartners:
            return this.chartsService.getTotalPartnersMetrics(filters);
          default:
            return this.chartsService.getSpouseCurrentlyEmployedMetrics(
              filters,
              this.partnerId
            );
        }
      }),
      tap((results) => {
        this.lineChartMetrics = results.data;
        this.updateChart();
        this.isLoading = false;
      })
    );
  }

  private setChartValues(): void {
    switch (this.lineChart) {
      case LineChart.CandidateSearch:
        this.chartId = 'lineChart';
        if (this.partnerId) this.chartId += this.partnerId;
        this.chartTitle = 'Candidate Searches';
        break;
      case LineChart.SpousesHired:
        this.chartId = 'spousesHiredChart';
        if (this.partnerId) this.chartId += this.partnerId;
        this.chartTitle = 'Spouses Hired';
        break;
      case LineChart.SpousesCurrentlyEmployed:
        this.chartId = 'spousesCurrentlyEmployedChart';
        if (this.partnerId) this.chartId += this.partnerId;
        this.chartTitle = 'Spouses Currently Employed';
        break;
      case LineChart.TotalPartners:
        this.chartId = 'totalPartnersChart';
        this.chartTitle = 'Total Partners';
        break;
      default:
        break;
    }
  }

  private setDateFilterDefaults(): void {
    const today = new Date();
    this.selectedEndDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );
    today.setDate(today.getDate() - 365);
    this.selectedStartDate = new Date(today.getFullYear(), today.getMonth(), 1);

    this.defaultFilter.startDate =
      new DatePipe('en-US').transform(
        this.selectedStartDate,
        this.dateFormat
      ) ?? '';
    this.defaultFilter.endDate =
      new DatePipe('en-US').transform(this.selectedEndDate, this.dateFormat) ??
      '';
  }

  private updateChart(): void {
    this.chart.data.labels = [];
    this.chart.data.datasets[0].data = [];
    this.chart.data.labels = this.lineChartMetrics.map((x) =>
      new DatePipe('en-US').transform(x.date, 'yyyy-MM')
    );
    this.chart.data.datasets[0].data = this.lineChartMetrics.map(
      (x) => x.count
    );
    this.chart.update();
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
    this.subscription.unsubscribe();
  }
}
