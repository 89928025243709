import { Component, Input, OnDestroy } from '@angular/core';
import { PartnerReports } from 'core/enums';
import { BlobService } from 'core/services/blob.service';
import { PartnerReportService } from 'core/services/partner/partner-report.service';
import { ReportFilters, ReportService } from 'core/services/report.service';
import { DateUtilsService } from 'core/utilities/date-utils.service';
import { map, Observable, Subscription, tap } from 'rxjs';
import { ServerBlobResult } from 'shared/models/server-blob-result';

@Component({
  selector: 'msep-export-report-button',
  templateUrl: './export-report-button.component.html',
  styleUrls: ['./export-report-button.component.scss'],
})
export class ExportReportButtonComponent implements OnDestroy {
  @Input() disabled = false;
  @Input() inButtonGroup = false;
  @Input() partnerReport!: PartnerReports;
  @Input() organizationId?: number;
  @Input() reportMetricId?: number;
  @Input() employmentDetailId?: number;
  @Input() currentStatus?: boolean;
  @Input() getTotals?: boolean;
  @Input() specialistId?: number;
  filters!: ReportFilters;
  isLoading = false;
  reportObservable$!: Observable<ServerBlobResult>;
  private subscriptions: Subscription[] = [];
  constructor(
    private blobService: BlobService,
    private dateUtilsService: DateUtilsService,
    private partnerReportService: PartnerReportService,
    private reportService: ReportService
  ) {}

  onExportClick(): void {
    this.isLoading = true;
    this.setObservable();
    const subscription = this.reportObservable$.subscribe({
      next: (result) => {
        this.blobService.download(result);
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((x) => x.unsubscribe());
  }

  private cleanUpFilter(filters: ReportFilters): ReportFilters {
    if (!filters.startDate || !filters.endDate) {
      // there are no dates, so set them
      const fyDetails = this.dateUtilsService.getFyDetails();
      filters.startDate = fyDetails.startDate.toLocaleDateString();
      filters.endDate = fyDetails.endDate.toLocaleDateString();
    }

    // clean up filters
    this.dateUtilsService.convertStartAndEndDateFilters(filters);
    return filters;
  }

  private getFilter(): void {
    let filtersSubscription!: Subscription;

    switch (this.partnerReport) {
      case PartnerReports.PartnerJobs:
      case PartnerReports.PartnerEngagement:
      case PartnerReports.HiringData:
      case PartnerReports.TeleworkRemote:
      case PartnerReports.CandidateStatus:
      case PartnerReports.SpecialistSpouseEngagement:
      case PartnerReports.SpecialistSpousesHired:
      case PartnerReports.SpecialistHotJobs:
        filtersSubscription = this.reportService.reportSubject
          .pipe(
            map((filters: ReportFilters) => {
              return this.cleanUpFilter(filters);
            }),
            tap((cleanedFilters) => (this.filters = cleanedFilters))
          )
          .subscribe();
        break;
      case PartnerReports.SpouseHire:
      case PartnerReports.SpouseEmploymentDetails:
        filtersSubscription = this.partnerReportService.reportSubject
          .pipe(
            map((filters: ReportFilters) => {
              return this.cleanUpFilter(filters);
            }),
            tap((cleanedFilters) => (this.filters = cleanedFilters))
          )
          .subscribe();
        break;
    }

    this.subscriptions.push(filtersSubscription);
  }

  private setObservable(): void {
    this.getFilter();

    switch (this.partnerReport) {
      case PartnerReports.PartnerJobs:
        this.setPartnerJobsObservable();
        break;
      case PartnerReports.PartnerEngagement:
        this.setPartnerEngagementObservable();
        break;
      case PartnerReports.HiringData:
        this.setHiringDataObservable();
        break;
      case PartnerReports.TeleworkRemote:
        this.setTeleworkRemoteObservable();
        break;
      case PartnerReports.CandidateStatus:
        this.setCandidateStatusObservable();
        break;
      case PartnerReports.SpecialistHotJobs:
        this.setSpecialistHotJobsObservable();
        break;
      case PartnerReports.SpecialistSpouseEngagement:
        this.setSpecialistSpouseEngagementObservable();
        break;
      case PartnerReports.SpecialistSpousesHired:
        this.setSpecialistSpousesHiredObservable();
        break;
      case PartnerReports.SpouseHire:
        this.setSpouseHireObservable();
        break;
      case PartnerReports.SpouseEmploymentDetails:
        this.setSpouseEmploymentDetailsObservable();
        break;
    }
  }

  private setPartnerJobsObservable(): void {
    this.reportObservable$ = this.reportService.exportPartnerJobs({
      partnerIds: this.organizationId ? [this.organizationId] : [],
      specialistId: this.filters.specialistId ?? 0,
    });
  }

  private setPartnerEngagementObservable(): void {
    this.reportObservable$ = this.reportService.exportPartnerEngagement({
      startDate: <string>this.filters.startDate,
      endDate: <string>this.filters.endDate,
      organizationId: this.organizationId ?? 0,
      specialistId: this.filters.specialistId ?? 0,
    });
  }

  private setHiringDataObservable(): void {
    this.reportObservable$ = this.reportService.exportPartnersAndSpousesHired();
  }

  private setTeleworkRemoteObservable(): void {
    this.reportObservable$ = this.reportService.exportTeleworkRemote({
      organizationId: this.organizationId ?? 0,
      specialistId: this.filters.specialistId ?? 0,
    });
  }

  private setCandidateStatusObservable(): void {
    this.reportObservable$ = this.reportService.exportCandidateStatusReport({
      organizationId: this.organizationId ?? 0,
      currentStatus: this.currentStatus ?? false,
    });
  }

  private setSpecialistSpouseEngagementObservable(): void {
    this.reportObservable$ =
      this.reportService.exportSpecialistSpouseEngagement({
        startDate: <string>this.filters.startDate,
        endDate: <string>this.filters.endDate,
        organizationId: this.organizationId ?? 0,
        employmentDetailId: this.filters.employmentDetailId ?? 0,
        getTotals: this.filters.getTotals ?? false,
        specialistId: this.filters.specialistId ?? 0,
      });
  }

  private setSpecialistHotJobsObservable(): void {
    this.reportObservable$ = this.reportService.exportSpecialistHotJobs({
      organizationId: this.organizationId ?? 0,
      specialistId: this.filters.specialistId ?? 0,
    });
  }

  private setSpecialistSpousesHiredObservable(): void {
    this.reportObservable$ = this.reportService.exportSpecialistSpousesHired({
      startDate: <string>this.filters.startDate,
      endDate: <string>this.filters.endDate,
      organizationId: this.organizationId ?? 0,
      reportMetricId: this.reportMetricId ?? 0,
      getTotals: this.getTotals ?? false,
      specialistId: this.filters.specialistId ?? 0,
    });
  }

  private setSpouseHireObservable(): void {
    this.reportObservable$ = this.partnerReportService.exportSpousesHiredData(
      {
        startDate: <string>this.filters.startDate,
        endDate: <string>this.filters.endDate,
        reportMetricId: this.filters.reportMetricId ?? 0,
        getTotals: this.filters.getTotals ?? false,
      } as ReportFilters,
      this.organizationId ?? 0
    );
  }

  private setSpouseEmploymentDetailsObservable(): void {
    this.reportObservable$ =
      this.partnerReportService.exportSpousesEmploymentDetails(
        {
          startDate: <string>this.filters.startDate,
          endDate: <string>this.filters.endDate,
          employmentDetailId: this.filters.employmentDetailId ?? 0,
          getTotals: this.filters.getTotals ?? false,
        } as ReportFilters,
        this.organizationId ?? 0
      );
  }
}
