<msep-page-title>
  <h1>Forgot Password</h1>
  <h2>
    Please provide the email that you use to log in to the MSEP Partner Portal.
  </h2>
</msep-page-title>

<div class="flex flex-col justify-center align-center">
  <msep-alert
    title="Your link has expired"
    [type]="expiredTokenAlertType"
    *ngIf="hasExpiredToken"
  >
    <p>
      Please provide your email below or submit a
      <a role="link" href [routerLink]="['/notice-and-consent', 'contact-us']"
        >Contact Us</a
      >
      form for technical support.
    </p>
  </msep-alert>
  <mat-card class="text-center width-600 flex flex-col">
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <msep-server-validation-message></msep-server-validation-message>
        <div formGroupName="emailGroup">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              type="text"
              matInput
              placeholder="Enter your email address"
              formControlName="email"
              required
            />
            <mat-icon matIconPrefix>alternate_email</mat-icon>
            <mat-error>
              <msep-form-error
                [control]="form.get('emailGroup.email')"
              ></msep-form-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Confirm Email</mat-label>
            <input
              type="text"
              matInput
              placeholder="Confirm your email address"
              formControlName="confirmEmail"
              required
              [errorStateMatcher]="matcher"
            />
            <mat-icon matIconPrefix>alternate_email</mat-icon>
            <mat-error>
              <msep-form-error
                [control]="form.get('emailGroup.confirmEmail')"
              ></msep-form-error>
              <msep-form-error
                *ngIf="!form.get('emailGroup.confirmEmail')?.errors"
                [control]="form.get('emailGroup')"
              ></msep-form-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex justify-center align-center">
          <p>
            <re-captcha
              formControlName="reCaptcha"
              [siteKey]="recaptchaSiteKey"
            ></re-captcha>
          </p>
        </div>
        <button
          type="submit"
          class="full-width"
          mat-raised-button
          color="primary"
          [disabled]="!form.valid || isLoading"
          [class.spinner]="isLoading"
        >
          Submit
        </button>
      </form>
    </mat-card-content>
    <mat-card-footer *ngIf="isLoading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
