import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from 'shared/components/confirmation-dialog/confirmation-dialog.component';
import { GeneralDialogComponent } from 'shared/components/general-dialog/general-dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
  confirmDialogRef!: MatDialogRef<ConfirmationDialogComponent>;
  constructor(private dialog: MatDialog) {}

  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Is it OK?');

    return of(confirmation);
  }

  generalConfirmation(
    message?: string
  ): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: message || 'Is it OK?',
        buttonYes: 'Ok',
        buttonNo: 'Cancel',
      },
    });
  }

  generalDialog(
    title: string,
    templateRef: TemplateRef<unknown> | undefined,
    width = '80vw'
  ): MatDialogRef<GeneralDialogComponent> {
    return this.dialog.open(GeneralDialogComponent, {
      data: {
        templateRef: templateRef,
        title: title,
      },
      width: width,
    });
  }

  showDeleteConfirmation(
    dialogContent: MsepDialogContent | null = null
  ): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title:
          dialogContent?.title ??
          'Are you sure you want to delete this record?',
        message:
          dialogContent?.message ??
          'You will not be able to recover this information!',
        buttonYes: dialogContent?.buttonYes ?? 'Delete',
        buttonNo: dialogContent?.buttonNo ?? 'Cancel',
      },
    });
  }

  governmentExitModal(href: string): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Leaving the Military Spouse Employment Partnership portal',
        message:
          'You are about to leave the MSEP Partner portal. Thank you for visiting!',
        buttonYes: 'Ok',
        buttonNo: 'Cancel',
        linkUrl: href,
      },
    });
  }
}

export interface MsepDialogContent {
  title: string | null;
  message: string | null;
  buttonYes: string | null;
  buttonNo: string | null;
}
