<div
  class="flex flex-col flex-wrap flex-gt-sm-row grid-gap-10 margin-top-20"
  cdkDropListGroup
  *ngIf="docks"
>
  <div
    class="flex flex-100 flex-gt-xs-50 flex-gt-md-33-33 flex-col col-gap-10"
    *ngFor="let column of docks; let i = index"
    cdkDropList
    [cdkDropListData]="column"
  >
    <div
      *ngFor="let dock of column"
      [cdkDragDisabled]="roleId !== undefined"
      cdkDrag
      (cdkDragDropped)="dropped($event)"
    >
      <ng-container [ngSwitch]="dock.dockId">
        <msep-help-desk-dock
          *ngSwitchCase="helpDesk"
          [isCollapsed]="dock.isCollapsed"
          (toggleCollapseDock)="toggleCollapse(dock)"
        ></msep-help-desk-dock>
        <msep-recently-accessed-partners-dock
          *ngSwitchCase="recentlyAccessed"
          [isCollapsed]="dock.isCollapsed"
          (toggleCollapseDock)="toggleCollapse(dock)"
        ></msep-recently-accessed-partners-dock>
        <msep-spouse-activity-dock
          *ngSwitchCase="spouseActivity"
          [isCollapsed]="dock.isCollapsed"
          (toggleCollapseDock)="toggleCollapse(dock)"
        ></msep-spouse-activity-dock>
        <msep-partner-metrics-dock
          *ngSwitchCase="partnerMetrics"
          [isCollapsed]="dock.isCollapsed"
          (toggleCollapseDock)="toggleCollapse(dock)"
        ></msep-partner-metrics-dock>
        <msep-jobs-dock
          *ngSwitchCase="jobs"
          [isCollapsed]="dock.isCollapsed"
          [isAdminView]="roleId !== undefined"
          (toggleCollapseDock)="toggleCollapse(dock)"
        ></msep-jobs-dock>
        <msep-partner-engagement-dock
          *ngSwitchCase="partnerEngagement"
          [isCollapsed]="dock.isCollapsed"
          (toggleCollapseDock)="toggleCollapse(dock)"
        ></msep-partner-engagement-dock>
      </ng-container>
    </div>
  </div>
  <ng-template #loading>
    <msep-centered-loader></msep-centered-loader>
  </ng-template>
</div>

<msep-ghost-loader-list
  class="row-in-column margin-top-20 flex flex-row flex-wrap grid-gap-8 grid-gap-gt-sm-16"
  [type]="ghostLoaderType"
  [ghostLength]="6"
  [childFlex]="100"
  [childFlexGtSm]="33"
  *ngIf="!docks"
></msep-ghost-loader-list>
