import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from 'core/utilities/utils.service';
import * as moment from 'moment';
import { Observable, catchError, throwError, map } from 'rxjs';
import { ConfigService } from '../infrastructure/config.service';
import { JwtService } from '../infrastructure/jwt.service';
import { LineChartMetrics, LineChartMetricsFilter } from '../charts.service';
import { ServerBlobResult } from 'shared/models/server-blob-result';

@Injectable({
  providedIn: 'root',
})
export class SecoService {
  private savedFilters: CandidateSearchForm | null = null;
  private secoApiUrlRoot = this.configService.config.secoApiBaseUrl;
  private secoApiUrl = `${this.secoApiUrlRoot}/external/msep`;

  constructor(
    private configService: ConfigService,
    private readonly jwtService: JwtService,
    private http: HttpClient,
    private utilService: UtilsService
  ) {}

  clearSavedFilters(): void {
    this.savedFilters = null;
  }

  downloadProfile(id: number): Observable<Blob> {
    const url = `${this.secoApiUrl}/spouseprofilepdf/${id}`;
    const headers = this.getHeader();

    return this.http.get(url, { responseType: 'blob', headers }).pipe(
      map(
        (response: Blob) => new Blob([response], { type: 'application/pdf' })
      ),
      catchError(this.handleError)
    );
  }

  downloadResume(userId: number): Observable<Blob> {
    const url = `${this.secoApiUrl}/spouse/resume`;
    const headers = this.getHeader();
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties({ userId: userId }),
    });

    return this.http.get(url, { responseType: 'blob', headers, params }).pipe(
      map((response: Blob) => new Blob([response], { type: response.type })),
      catchError(this.handleError)
    );
  }

  downloadSpouseFile(
    endpoint?: string,
    partnerId?: number,
    partnerName?: string
  ): Observable<Blob> {
    const url = `${this.secoApiUrlRoot}${endpoint}&partnerId=${partnerId}&partnerName=${partnerName}`;
    const headers = this.getHeader();

    return this.http.get(url, { responseType: 'blob', headers }).pipe(
      map((response: Blob) => new Blob([response], { type: response.type })),
      catchError(this.handleError)
    );
  }

  getCandidateByProfileId(id: number): Observable<Candidate> {
    const url = `${this.secoApiUrl}/candidateDetails`;
    const params = {
      profileId: id,
    };

    return this.http
      .post<SecoApiSingleResponse<Candidate>>(url, params, {
        headers: this.getHeader(),
      })
      .pipe(
        map((response) => response.data),
        catchError(this.handleError)
      );
  }

  getClearance(): Observable<Resource[]> {
    // * This is a call in MSEP to SECO that does not follow the config path.
    return this.http
      .get<SecoApiResponse<Resource>>(
        `${this.secoApiUrlRoot}/lookups/clearance`
      )
      .pipe(
        map((response) => response.data),
        catchError(this.handleError)
      );
  }

  getClickedApplyCount(
    params: SecoReportSearchParameters
  ): Observable<ClickApplyResponse> {
    const url = `${this.secoApiUrl}/MsepApplyClick`;

    return this.http
      .post<ClickApplyResponse>(url, params, {
        headers: this.getHeader(),
      })
      .pipe(catchError(this.handleError));
  }

  getClickedApplyExport(filters: SecoReportSearchParameters): Observable<Blob> {
    const url = `${this.secoApiUrl}/MsepApplyClick/export`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    const headers = this.getHeader();

    return this.http.get(url, { responseType: 'blob', headers, params }).pipe(
      map((response: Blob) => new Blob([response], { type: 'text/xml' })),
      catchError(this.handleError)
    );
  }

  getEducationLevels(): Observable<Resource[]> {
    return this.http
      .get<Resource[]>(`${this.secoApiUrl}/educationlevelsearch`, {
        headers: this.getHeader(),
      })
      .pipe(catchError(this.handleError));
  }

  getJobTypes(): Observable<Resource[]> {
    // * This is a call in MSEP to SECO that does not follow the config path.
    return this.http
      .get<SecoApiResponse<Resource>>(`${this.secoApiUrlRoot}/lookups/jobtypes`)
      .pipe(
        map((response) => response.data),
        catchError(this.handleError)
      );
  }

  getJobSearchStatuses(): Observable<Resource[]> {
    // * This is a call in MSEP to SECO that does not follow the config path.
    return this.http
      .get<SecoApiResponse<Resource>>(
        `${this.secoApiUrlRoot}/lookups/JobSearchStatus`
      )
      .pipe(
        map((response) => response.data),
        catchError(this.handleError)
      );
  }

  getSpouseEngagementChartData(
    filters: LineChartMetricsFilter
  ): Observable<SecoApiSingleResponse<SecoLineChartMetrics>> {
    const url = `${this.secoApiUrl}/charts/spouse-engagement`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<SecoApiSingleResponse<SecoLineChartMetrics>>(url, {
        headers: this.getHeader(),
        params: params,
      })
      .pipe(catchError(this.handleError));
  }

  exportSpouseEngagementChartData(
    filters: LineChartMetricsFilter
  ): Observable<ServerBlobResult> {
    const url = `${this.secoApiUrl}/charts/spouse-engagement/export`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    const headers = this.getHeader();

    return this.http
      .get<ServerBlobResult>(url, { headers, params })
      .pipe(catchError(this.handleError));
  }

  getProfileViewCount(
    params: SecoReportSearchParameters
  ): Observable<SecoReportResponse> {
    const url = `${this.secoApiUrl}/mseppartnerprofileviewcountreport`;

    return this.http
      .post<SecoReportResponse>(url, params, {
        headers: this.getHeader(),
      })
      .pipe(catchError(this.handleError));
  }

  getSpouseSavedData(
    filters: SpouseReportFilters
  ): Observable<SecoSpouseReportResponse> {
    const url = `${this.secoApiUrl}/partner/spouse-saves`;
    const params = new HttpParams({
      fromObject: {
        partnerId: filters.partnerId,
        skip: filters.skip,
        take: filters.take,
        sortField: filters.sortField ?? '',
        sortDirection: filters.sortDirection ?? '',
      },
    });

    return this.http
      .get<SecoSpouseReportResponse>(url, {
        headers: this.getHeader(),
        params,
      })
      .pipe(catchError(this.handleError));
  }

  getSpouseSavedExport(filters: SpouseReportFilters): Observable<Blob> {
    const url = `${this.secoApiUrl}/partner/spouse-saves/export`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    const headers = this.getHeader();

    return this.http.get(url, { responseType: 'blob', headers, params }).pipe(
      map((response: Blob) => new Blob([response], { type: 'text/xml' })),
      catchError(this.handleError)
    );
  }

  getSpouseViewedData(
    filters: SpouseReportFilters
  ): Observable<SecoSpouseReportResponse> {
    const url = `${this.secoApiUrl}/partner/page-view`;
    const params = new HttpParams({
      fromObject: {
        partnerId: filters.partnerId,
        skip: filters.skip,
        take: filters.take,
        sortField: filters.sortField ?? '',
        sortDirection: filters.sortDirection ?? '',
      },
    });

    return this.http
      .get<SecoSpouseReportResponse>(url, {
        headers: this.getHeader(),
        params,
      })
      .pipe(catchError(this.handleError));
  }

  getSpouseViewedExport(filters: SpouseReportFilters): Observable<Blob> {
    const url = `${this.secoApiUrl}/partner/page-view/export`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    const headers = this.getHeader();

    return this.http.get(url, { responseType: 'blob', headers, params }).pipe(
      map((response: Blob) => new Blob([response], { type: 'text/xml' })),
      catchError(this.handleError)
    );
  }

  getSavedFilters(): CandidateSearchForm | null {
    return this.savedFilters;
  }

  getSavedProfileCount(
    params: SecoReportSearchParameters
  ): Observable<SecoReportResponse> {
    const url = `${this.secoApiUrl}/getpartnerbyid`;

    return this.http
      .get<SecoReportResponse>(url, {
        headers: this.getHeader(),
        params: {
          partnerId: params.partnerId.toString(),
          startDate: moment(params.startDate ?? new Date('1970-01-01')).format(
            'YYYY-MM-DD'
          ),
          endDate: moment(params.endDate ?? Date.now()).format('YYYY-MM-DD'),
        },
      })
      .pipe(catchError(this.handleError));
  }

  getSearchData(
    filters: SpouseReportFilters
  ): Observable<SecoSpouseReportResponse> {
    const url = `${this.secoApiUrl}/spouse/searched-partner`;
    const params = new HttpParams({
      fromObject: {
        partnerId: filters.partnerId,
        skip: filters.skip,
        take: filters.take,
        sortField: filters.sortField ?? '',
        sortDirection: filters.sortDirection ?? '',
        searchedStartDate: filters.searchedStartDate ?? '',
        searchedEndDate: filters.searchedEndDate ?? '',
      },
    });

    return this.http
      .get<SecoSpouseReportResponse>(url, {
        headers: this.getHeader(),
        params,
      })
      .pipe(catchError(this.handleError));
  }

  getSpouseSearchExport(filters: SpouseReportFilters): Observable<Blob> {
    const url = `${this.secoApiUrl}/spouse/searched-partner/export`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    const headers = this.getHeader();

    return this.http.get(url, { responseType: 'blob', headers, params }).pipe(
      map((response: Blob) => new Blob([response], { type: 'text/xml' })),
      catchError(this.handleError)
    );
  }

  saveFilters(filters: CandidateSearchForm): void {
    this.savedFilters = filters;
  }

  searchCandidate(
    candidate: CandidateSearchForm
  ): Observable<SecoApiSingleResponse<CandidateList[]>> {
    candidate.searchByMsepUserId = this.jwtService.getUserId();
    const secoFormattedParams = this.secoFixFilter(candidate);
    const cleanedParams =
      this.utilService.removeFalseyProperties(secoFormattedParams);

    return this.http
      .post<SecoApiSingleResponse<CandidateList[]>>(
        `${this.secoApiUrl}/Candidate`,
        cleanedParams,
        {
          headers: this.getHeader(),
        }
      )
      .pipe(catchError(this.handleError));
  }

  querySkills(value: string): Observable<Skill[]> {
    return this.http
      .get<Skill[]>(`${this.secoApiUrl}/skills?value=${value}`, {
        headers: this.getHeader(),
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  private getHeader(): HttpHeaders {
    return new HttpHeaders({
      apiKey: this.jwtService.getValue('secoApiKey')?.toString() ?? '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'content-type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'access-control-Allow-Origin': '*',
    });
  }

  private secoFixFilter(candidate: CandidateSearchForm): CandidateSearchParams {
    const candidateParams: CandidateSearchParams = {};
    candidateParams.email = candidate.email;
    candidateParams.firstName = candidate.firstName;
    candidateParams.hasLinkedInUrl = candidate.hasLinkedInUrl;
    candidateParams.jobSearchStatusId = candidate.jobSearchStatusId;
    candidateParams.keyword = candidate.keyword;
    candidateParams.lastName = candidate.lastName;
    candidateParams.latitude = candidate.latitude;
    candidateParams.longitude = candidate.longitude;
    candidateParams.locationDistance = candidate.locationDistance;
    candidateParams.profilePercentage = candidate.profilePercentage;
    candidateParams.searchByMsepPartnerId = candidate.searchByMsepPartnerId;
    candidateParams.searchByMsepUserId = candidate.searchByMsepUserId;
    candidateParams.stateId = candidate.stateId;
    candidateParams.skip = candidate.skip;
    candidateParams.take = candidate.take;
    candidateParams.searchResumes = candidate.searchResumes;

    if (candidate.clearancesId) {
      const arr: number[] = [];
      arr.push(candidate.clearancesId);
      candidateParams.clearanceList = arr;
    }
    if (candidate.educationId) {
      const arr: number[] = [];
      arr.push(candidate.educationId);
      candidateParams.educationIds = arr;
    }
    if (candidate.includeSavedCandidates) {
      candidateParams.profileList = candidate.profileList;
    }
    if (candidate.jobTypeId) {
      const arr: number[] = [];
      arr.push(candidate.jobTypeId);
      candidateParams.jobTypeIds = arr;
    }
    if (candidate.skill) {
      const arr: string[] = [];
      arr.push(candidate.skill);
      candidateParams.skills = arr;
    }

    return candidateParams;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface AdditionalLocation {
  city: string;
  state: string;
}

export interface Resource {
  id: number;
  description: string;
}

export interface PartnerSpouseLineGraphMetrics {
  date: string;
  count: number;
}

export interface CandidateSearchParams {
  skills?: string[];
  email?: string;
  jobSearchStatusId?: string;
  firstName?: string;
  lastName?: string;
  educationIds?: number[];
  clearanceList?: number[];
  hasLinkedInUrl?: boolean;
  jobTypeIds?: number[];
  profileList?: number[];
  profilePercentage?: number;
  keyword?: string;
  latitude?: number;
  longitude?: number;
  locationDistance?: number;
  skip?: number;
  take?: number;
  stateId?: string;
  searchByMsepPartnerId?: number;
  searchByMsepUserId?: number;
  searchResumes?: boolean;
}

export interface CandidateSearchForm {
  skill?: string;
  email?: string;
  jobSearchStatusId?: string;
  firstName?: string;
  lastName?: string;
  educationId?: number;
  hasLinkedInUrl?: boolean;
  includeSavedCandidates?: boolean;
  keyword?: string;
  googlePlaceId?: string;
  latitude?: number;
  longitude?: number;
  locationDistance?: number;
  stateId?: string;
  skip?: number;
  take?: number;
  searchByMsepPartnerId?: number;
  searchByMsepUserId?: number;
  clearancesId?: number;
  jobTypeId?: number;
  profilePercentage?: number;
  profileList?: number[];
  searchResumes?: boolean;
}

export interface SecoApiResponse<T> {
  data: T[];
  errors?: string[];
  httpStatusCode?: number;
  total: number;
}

export interface SecoApiSingleResponse<T> {
  data: T;
  errors?: string[];
  httpStatusCode?: number;
  total: number;
}

export interface Skill {
  description?: string;
  skillDescription?: string;
}

export interface Candidate {
  additionalLocations?: AdditionalLocation[];
  address?: string;
  certificationLicenses?: Certification[];
  city?: string;
  education?: Education[];
  email?: string;
  fullName?: string;
  linkedInProfileUrl?: string;
  locationZip?: string;
  phone?: string;
  profileId?: number;
  skills?: Skill[];
  state?: string;
  summary?: string;
  workExperience?: WorkExperience[];
  primaryResumeEndpoint?: string;
  primaryCoverLetterEndpoint?: string;
}

export interface CandidateList {
  careerLevel?: string;
  city?: string;
  email?: string;
  fullName?: string;
  highestEducationLevel?: string;
  id?: number;
  jobSearchStatusDescription: string | null;
  jobTypes: Array<{ jobTypeDescription: string }>;
  linkedInProfileUrl?: string;
  profileId?: number;
  phone?: string;
  state?: string;
  zip?: string;
  primaryResumeEndpoint?: string;
  primaryCoverLetterEndpoint?: string;
  profileLastUpdatedDate?: string;
}

export interface Certification {
  id?: number;
  certificationDescription?: string;
}

export interface ClickApplyData {
  jobTitle?: string;
  email?: string;
  city?: string;
  state?: string;
  jobId?: string;
  spouseName?: string;
  profileId?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  linkedInUrl?: string;
}

export interface ClickApplyResponse {
  total?: number;
  data: ClickApplyData[];
}

export interface Education {
  degreeLevel?: string;
  fieldOfStudy?: string;
  profileId?: number;
  school?: string;
}

export interface SecoLineChartMetrics {
  clickApply: LineChartMetrics[];
  viewProfile: LineChartMetrics[];
  searchProfile: LineChartMetrics[];
  saveProfile: LineChartMetrics[];
}

export interface SecoReportSearchParameters {
  partnerId: number;
  startDate?: string;
  endDate?: string;
  latitude?: number;
  longitude?: number;
  jobId?: string;
  title?: string;
  skip: number;
  take: number;
  sortField?: string;
  sortDirection?: string;
}

export interface SecoReportResponse {
  total?: number;
  data: SecoReportData[];
}

export interface SpouseReportFilters {
  partnerId: number;
  skip: number;
  take: number;
  sortField?: string;
  sortDirection?: string;
  searchedStartDate?: string;
  searchedEndDate?: string;
}

export interface SecoReportData {
  count?: number;
  date?: Date;
}

export interface SecoSpouseReportResponse {
  total?: number;
  data: SecoSpouseReportData[];
}

export interface SecoSpouseReportData {
  spouseProfileId?: number;
  spouseFirstName?: string;
  spouseLastName?: string;
  linkedInProfileUrl?: string;
  email?: string;
  phoneNumber?: string;
  primaryResumeEndpoint?: string;
  primaryCoverLetterEndpoint?: string;
  searchedDate?: Date;
  profileId?: number;
}

export interface WorkExperience {
  companyName?: string;
  endDate?: Date;
  jobDescription?: string;
  jobTitle?: string;
  profileId?: number;
  startDate?: Date;
}
