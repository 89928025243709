import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { roundDown } from 'core/utilities/mat-utils';
import { UtilsService } from 'core/utilities/utils.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'shared/models/api-response';
import { ConfigService } from './infrastructure/config.service';

@Injectable({ providedIn: 'root' })
export class ResourcesService {
  private partnerApiBaseUrl = this.configService.config.msepPartnerApiBaseUrl;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private utilService: UtilsService
  ) {}

  getAccountStatuses(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/account-statuses`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getAllSpecialists(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/all-specialists`
      )
      .pipe(
        map((result) => result.data),
        catchError((error) => this.handleError(error))
      );
  }

  getApplicationStatuses(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/application-statuses`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getAuthenticationLogReasons(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/authentication-log-reasons`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getCandidateStatuses(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/candidate-statuses`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getCandidateTags(
    searchTerm: string,
    organizationId: number
  ): Observable<Resource[]> {
    const filters: CandidateTagFilter = {
      searchTerm: searchTerm,
      organizationId: organizationId,
    };
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/candidate-tags`,
        {
          params: this.utilService.removeFalseyProperties(filters),
        }
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getCareerLevels(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/career-levels`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getCategories(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/content-categories`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getChallengeQuestions(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/challenge-questions`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getCompensationTypes(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/compensation-types`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getContactUsAssistanceTypes(
    isRegistered?: boolean
  ): Observable<ContactUsAssistanceType[]> {
    return this.http
      .get<ApiResponse<ContactUsAssistanceType[]>>(
        `${this.partnerApiBaseUrl}/resources/contact-us-assistance-types`
      )
      .pipe(
        map((result) => {
          return isRegistered !== undefined
            ? result.data.filter((type) => type.forRegistered === isRegistered)
            : result.data;
        }),
        catchError(this.handleError)
      );
  }

  getContactUsContactWindows(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/contact-us-contact-windows`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getContactUsPreferredCommunications(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/contact-us-preferred-communications`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getContactUsTicketStatuses(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/contact-us-ticket-statuses`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getCountry(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/countries`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getEducationLevels(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/education-levels`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getEmploymentDetailTypes(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/employment-detail-types`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getEventLogMinimumLevels(): Observable<EventLogMinimumLevel[]> {
    return this.http
      .get<ApiResponse<EventLogMinimumLevel[]>>(
        `${this.partnerApiBaseUrl}/resources/event-log-minimum-levels`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getEventLogTitles(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/event-log-titles`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getFaqCategories(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/faq-categories`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getFaqs(): Observable<Faq[]> {
    return this.http
      .get<ApiResponse<Faq[]>>(`${this.partnerApiBaseUrl}/resources/faqs`)
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getFiscalYears(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/fiscal-years`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getIndustrySectors(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/industry-sectors`
      )
      .pipe(
        map((result: ApiResponse<Resource[]>) => result.data),
        catchError(this.handleError)
      );
  }

  getJobBenefits(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/job-benefits`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getJobCommunicationMethods(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/job-communication-methods`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getJobFeedImportStatus(): Observable<StringResourceValue[]> {
    return of([
      { value: 'Failed', description: 'Failed' },
      { value: 'Succeeded', description: 'Succeeded' },
      { value: 'Running', description: 'Running' },
      { value: 'Unknown', description: 'Unknown' },
    ]);
  }

  getJobFeedImportSteps(): Observable<StringResourceValue[]> {
    return of([
      { value: 'Sourcing', description: 'Sourcing' },
      { value: 'Extracting', description: 'Extracting' },
      { value: 'Transforming', description: 'Transforming' },
      { value: 'Done', description: 'Done' },
    ]);
  }

  getJobFeedParameters(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/job-feed-parameters`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getJobFeedTypes(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/job-feed-types`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getJobFeedSourceUrlTypes(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/job-feed-source-url-types`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getJobTypes(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/job-types`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getSpouseHiredReportMetricGroup(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/spouse-hired-metric-group`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getPartnershipTypes(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/partnership-types`
      )
      .pipe(
        map((result: ApiResponse<Resource[]>) => result.data),
        catchError(this.handleError)
      );
  }

  getPermissions(roleId?: number): Observable<Resource[]> {
    let url = `${this.partnerApiBaseUrl}/resources/permissions`;
    if (roleId) {
      url += `?roleId=${roleId}`;
    }
    return this.http.get<ApiResponse<Resource[]>>(url).pipe(
      map((result) => result.data),
      catchError(this.handleError)
    );
  }

  getResourceCategories(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/resource-categories`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getRoles(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(`${this.partnerApiBaseUrl}/resources/roles`)
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getRoundedTotalSpousesHired(): Observable<number> {
    return this.http
      .get<ApiResponse<{ count: number }>>(
        `${this.partnerApiBaseUrl}/resources/total-spouses-hired`
      )
      .pipe(
        map((result) => roundDown(result.data.count, 1000)),
        catchError(this.handleError)
      );
  }

  getStates(): Observable<StringResourceValue[]> {
    return this.http
      .get<ApiResponse<StateResourceValue[]>>(
        `${this.partnerApiBaseUrl}/resources/states`
      )
      .pipe(
        map((result) =>
          result.data.map((x) => ({
            value: x.id,
            description: x.description,
          }))
        ),
        catchError(this.handleError)
      );
  }

  getYearsOfExperience(): Observable<Resource[]> {
    return this.http
      .get<ApiResponse<Resource[]>>(
        `${this.partnerApiBaseUrl}/resources/years-of-experience`
      )
      .pipe(
        map((result) => result.data),
        catchError(this.handleError)
      );
  }

  getYesNoListBoolean(): Observable<BooleanResourceValue[]> {
    return of([
      { value: true, description: 'Yes' },
      { value: false, description: 'No' },
    ]);
  }

  getYesNoList(): Observable<StringResourceValue[]> {
    return of([
      { value: 'true', description: 'Yes' },
      { value: 'false', description: 'No' },
    ]);
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    return throwError(() => err || 'Server error');
  }
}

export interface CandidateTagFilter {
  searchTerm: string;
  organizationId: number;
}

export interface ContactUsAssistanceType extends Resource {
  forRegistered: boolean;
}

export interface EventLogMinimumLevel extends Resource {
  level: string;
  isActive: boolean;
}

export interface Faq {
  id: number;
  question: string;
  answer: string;
  category: string;
}

export interface Resource {
  id: number;
  description: string;
}

export interface StateResourceValue {
  id: string;
  description: string;
}

export interface StringResourceValue {
  value: string;
  description: string;
}

export interface BooleanResourceValue {
  value: boolean;
  description: string;
}
