<button
  mat-icon-button
  [routerLink]="['/alerts-and-information']"
  aria-label="Link to alerts and information page - warning icon"
  role="link"
>
  <ng-container *ngIf="systemAlert$ | async as systemAlert; else loading">
    <mat-icon
      [matBadge]="systemAlert.total"
      matBadgeSize="small"
      [matBadgeHidden]="systemAlert.total === 0"
      matBadgeColor="warn"
      aria-label="warning"
      aria-hidden="false"
      >warning
    </mat-icon>
  </ng-container>
  <ng-template #loading> <mat-icon>warning</mat-icon></ng-template>
</button>
