import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'core/services/authentication.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'msep-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  form!: UntypedFormGroup;
  isLoading = false;
  showPassword = false;

  private subscription: Subscription | undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private jwtService: JwtService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(256)],
      ],
      password: ['', [Validators.required]],
    });
  }

  onSubmit(): void {
    this.isLoading = true;

    this.subscription = this.authenticationService
      .logIn(this.form.value)
      .subscribe({
        next: (userHasLoggedInSuccessfully) => {
          if (userHasLoggedInSuccessfully) {
            this.jwtService.routeToHomePage();
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
