import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { Docks, GhostLoaderType } from 'core/enums';
import { DashboardService, Dock } from 'core/services/dashboard.service';

@Component({
  selector: 'msep-dashboard-docks',
  templateUrl: './dashboard-docks.component.html',
  styleUrls: ['./dashboard-docks.component.scss'],
})
export class DashboardDocksComponent implements OnInit {
  @Input() roleId?: number;

  docks!: Dock[][];
  ghostLoaderType = GhostLoaderType.PartnerLogo;
  helpDesk = Docks.Helpdesk;
  jobs = Docks.Jobs;
  partnerEngagement = Docks.PartnerEngagement;
  partnerMetrics = Docks.PartnerMetrics;
  recentlyAccessed = Docks.RecentlyAccessedPartner;
  spouseActivity = Docks.SpouseActivity;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    if (this.roleId) {
      this.dashboardService
        .getDefaultRoleDocks(this.roleId)
        .subscribe((result) => {
          this.docks = result;
        });
    } else {
      this.dashboardService.getDocks().subscribe((result) => {
        this.docks = result;
      });
    }
  }

  dropped(event: CdkDragDrop<any>): void {
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.updateDocks();
  }

  toggleCollapse(dock: Dock): void {
    dock.isCollapsed = !dock.isCollapsed;
  }

  private updateDocks(): void {
    const updatedDocks = new Array<Dock>();
    this.docks.forEach((column, columnIndex) => {
      column.forEach((dock, index) => {
        dock.column = columnIndex + 1;
        dock.sortOrder = index + 1;
        updatedDocks.push(dock);
      });
    });
    this.dashboardService.updateDocks(updatedDocks).subscribe();
  }
}
