<nav class="msep-side-nav">
  <div *ngIf="isAuthenticated" class="flex flex-col justify-end sidenav-header">
    <div>
      <div class="label-hello margin-top-20">Hello</div>
      <strong class="label-name"> {{ getDisplayName() }} </strong>
      <div class="label-login margin-top-20">Last Login</div>
      <div class="label-login-details">
        {{ getLastLoginDate() | localDateTime }}
      </div>
    </div>
  </div>

  <mat-divider *ngIf="isAuthenticated"></mat-divider>
  <mat-list class="nav-links">
    <ng-container *ngIf="isAuthenticated; else publicMenu">
      <mat-list-item>
        <a
          class="flex justify-end"
          aria-label
          role="navigation"
          [routerLink]="getHomepageLink()"
          (click)="toggle()"
        >
          Home
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="!userHasUserRole()">
        <a
          class="flex justify-end"
          aria-label
          role="navigation"
          [routerLink]="['/partner/search']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Partners
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="shouldShowPartnerDirectoryLink()">
        <a
          class="flex justify-end"
          aria-label
          role="navigation"
          [routerLink]="['/our-partners']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Partner Directory
        </a>
      </mat-list-item>
      <mat-accordion class="left sub-menu" *ngIf="userIsSysOp()">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Admin </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/authentication-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Authentication Logs
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/email-health-check']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Email Health Check
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/content-management/email-templates']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Email Templates
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/event-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Event Logs
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/exception-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Exception Logs
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/content-management/faqs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            FAQs
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/government-view']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Government Dashboard
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            *ngIf="userHasHelpDeskPermission()"
            [routerLink]="['/admin/contact-us-tickets']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Help Desk Tickets
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/job-feed-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Job Feed Logs
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            *ngIf="userHasManageJobsPermission()"
            [routerLink]="['/admin/job-feeds']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Job Feeds
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/jobs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Job Posts
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/content-management/manage-360-newsletters']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            360 Newsletters
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/content-management/partner-training-videos']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Partner Training Videos
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            *ngIf="userHasManageReportsPermission()"
            [routerLink]="['/admin/report-configuration']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Report Configuration
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/security-alert-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Security Alert Logs
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/admin/specialist-view']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Specialist Dashboard
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/content-management/system-alerts']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            System Alerts
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            *ngIf="userHasManageUsersPermission()"
            [routerLink]="['/admin/users']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Users
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-list-item *ngIf="shouldShowReportMenu()">
        <a
          class="flex justify-end"
          aria-label
          role="navigation"
          [routerLink]="['/report/dashboard']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Reports
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="!userHasUserRole()">
        <a
          class="flex justify-end"
          aria-label
          role="navigation"
          [routerLink]="['/charts/dashboard']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Charts
        </a>
      </mat-list-item>
      <mat-accordion class="left sub-menu">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Help </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/help/contact-us']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Contact Us
          </a>
          <a
            class="flex justify-end"
            aria-label
            role="navigation"
            [routerLink]="['/partner-resources']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Partner Resources
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-list-item *ngIf="userHasSpecialistRole() || userIsSysOp()">
        <a
          class="flex justify-end"
          aria-label
          role="navigation"
          [routerLink]="['/specialist/manage-partners']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Manage Organizations
        </a>
      </mat-list-item>
    </ng-container>
  </mat-list>

  <mat-divider></mat-divider>

  <div class="flex justify-center action-buttons">
    <button
      color="accent"
      mat-raised-button
      *ngIf="!isAuthenticated"
      (click)="onLogin()"
      role="link"
    >
      Log In
    </button>
    <button
      mat-raised-button
      (click)="onLogout()"
      *ngIf="isAuthenticated"
      role="link"
    >
      Log Out
    </button>
  </div>
</nav>

<ng-template #publicMenu>
  <div class="flex-col" class="secondaryLinks">
    <mat-list-item>
      <a
        class="flex justify-end align-center"
        aria-label
        role="navigation"
        [routerLink]="getHomepageLink()"
        (click)="toggle()"
      >
        Home
      </a>
    </mat-list-item>
    <mat-list-item>
      <a
        class="flex justify-end align-center"
        aria-label
        role="navigation"
        [routerLink]="['about-us']"
        (click)="toggle()"
      >
        About
      </a>
    </mat-list-item>
    <mat-accordion class="sub-menu">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="justify-end">
            The Partnership
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a
          class="flex justify-end align-center"
          aria-label
          role="navigation"
          [routerLink]="['/become-a-partner']"
          (click)="toggle()"
        >
          Become a Partner
        </a>
        <a
          class="flex justify-end align-center"
          aria-label
          role="navigation"
          [routerLink]="['/our-partners']"
          (click)="toggle()"
        >
          Our Partners
        </a>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-list-item>
      <a
        class="flex justify-end align-center"
        aria-label
        role="navigation"
        [routerLink]="['/media']"
        (click)="toggle()"
      >
        Media
      </a>
    </mat-list-item>
    <mat-list-item>
      <a
        class="flex justify-end align-center"
        aria-label
        role="navigation"
        [routerLink]="['/faqs']"
        (click)="toggle()"
      >
        FAQs
      </a>
    </mat-list-item>
    <mat-accordion class="sub-menu">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="justify-end"> Help </mat-panel-title>
        </mat-expansion-panel-header>
        <a
          class="flex justify-end align-center"
          aria-label
          role="navigation"
          [routerLink]="['/notice-and-consent', 'contact-us']"
          (click)="toggle()"
        >
          Contact Us
        </a>
        <a
          class="flex justify-end align-center"
          aria-label
          role="navigation"
          [routerLink]="['/partner-resources']"
          (click)="toggle()"
        >
          Partner Resources
        </a>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-template>
