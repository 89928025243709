import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PartnersService } from 'core/services/partners.service';
import { Resource } from 'core/services/resources.service';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'msep-partner-resources-autocomplete',
  templateUrl: './partner-resources-autocomplete.component.html',
  styleUrls: ['./partner-resources-autocomplete.component.scss'],
})
export class PartnerResourcesAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  @Input() label = 'Search for Resource';
  @Input() resource!: Resource;
  @Input() showHint = false;

  @Output() resourceSelected = new EventEmitter<Resource>();

  // this is a dummy input that the autocomplete and matInput need to satisfy
  // the compiler.
  inputControl = new UntypedFormControl('');
  partnerResourcesAutocomplete$: Observable<Resource[]> | undefined;

  constructor(
    @Optional() @Self() private controlDir: NgControl,
    private partnersService: PartnersService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (this.controlDir) {
      this.controlDir.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.partnerResourcesAutocomplete$ =
      this.controlDir.control?.valueChanges.pipe(
        startWith(''),
        switchMap((value) =>
          this.partnersService.getPartnerResourcesAutoComplete(value)
        )
      );
    if (this.resource) {
      this.inputControl.setValue(this.resource);
      this.autocompleteDisplay(this.resource);
      this.resourceSelected.emit(this.resource);
    }
  }

  autocompleteDisplay(resource: Resource | string): string {
    if (!resource) return '';
    return typeof resource === 'string' ? resource : resource.description;
  }

  onAutocompleteSelect(event: MatAutocompleteSelectedEvent): void {
    const selectedValue = event.option.value as Resource;
    this.resourceSelected.emit(selectedValue);
  }

  /*
      registerOnChange, registerOnTouched, and writeValue are all required by 
      the ControlValueAccessor interface.
    */
  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.inputControl.valueChanges.subscribe({
      next: (value) => {
        if (typeof value === 'string') {
          this.changeDetectorRef.detectChanges();
          fn(value);
        } else {
          fn(value);
        }
      },
    });
  }

  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    // no implementation necessary
  }

  // eslint-disable-next-line
  writeValue(obj: any): void {
    this.inputControl.setValue(obj ? obj : '');
  }
}
