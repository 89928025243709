<div [formGroup]="form" class="flex flex-row flex-wrap margin-20">
  <div
    class="flex flex-100 flex-row justify-end line-chart-partner-autocomplete"
  >
    <msep-partner-autocomplete
      *ngIf="partnerId === undefined && chartId !== 'totalPartnersChart'"
      label="Organization"
      formControlName="organizationName"
      (partnerSelected)="onOrganizationSelected($event)"
      class="flex flex-100 flex-gt-sm-45"
    ></msep-partner-autocomplete>
  </div>
  <div
    class="flex flex-100 flex-col flex-wrap flex-gt-sm-row grid-gap-1rem"
    [class]="
      partnerId === undefined
        ? 'justify-start'
        : 'line-chart-date-margin justify-end'
    "
  >
    <mat-form-field
      *ngIf="partnerId === undefined"
      class="flex flex-100 flex-gt-sm-50 margin-top-10"
      appearance="outline"
    >
      <mat-label>Partnership Type</mat-label>
      <mat-select
        formControlName="partnershipTypeId"
        (selectionChange)="onPartnershipTypeChanged($event)"
      >
        <mat-option
          *ngFor="let partnershipType of partnershipTypes$ | async"
          [value]="partnershipType.id"
        >
          {{ partnershipType.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex flex-row flex-100 flex-gt-sm-50 grid-gap-1rem">
      <msep-monthly-datepicker
        inputLabel="Start Date"
        [passedDate]="selectedStartDate"
        (selectedDateMonth)="onStartDateSelected($event)"
        class="flex-50"
      ></msep-monthly-datepicker>
      <msep-monthly-datepicker
        inputLabel="End Date"
        [passedDate]="selectedEndDate"
        (selectedDateMonth)="onEndDateSelected($event)"
        class="flex-50"
      ></msep-monthly-datepicker>
    </div>
  </div>
  <div class="flex flex-100 justify-start margin-bottom-10 reset-margin-top">
    <button mat-raised-button type="reset" (click)="onReset()">Reset</button>
    <msep-export-line-chart-button
      [chartId]="lineChartId"
      [organizationId]="partnerId"
      [filters]="currentFilter"
    ></msep-export-line-chart-button>
  </div>
</div>
<div class="chart-wrap">
  <canvas [id]="chartId">
    <ng-container *ngIf="lineChartMetrics$ | async">
      {{ chart }}
    </ng-container>
  </canvas>
</div>
