<msep-page-title>
  <h1>Log In</h1>
</msep-page-title>

<div class="flex flex-col justify-center align-center">
  <div class="flex flex-100">
    <mat-card class="login">
      <mat-card-subtitle class="text-center">
        Log in to your MSEP account
      </mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <msep-server-validation-message></msep-server-validation-message>
          <div class="flex flex-col gap-1rem">
            <mat-form-field class="flex" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                type="text"
                matInput
                placeholder="Enter your email address"
                formControlName="email"
                required
              />
              <mat-icon matIconPrefix>alternate_email</mat-icon>
              <mat-error>
                <msep-form-error
                  [control]="form.get('email')"
                ></msep-form-error>
              </mat-error>
              <mat-hint>Example: name&#64;example.com</mat-hint>
            </mat-form-field>
            <mat-form-field class="flex" appearance="outline">
              <mat-label>Password</mat-label>
              <input
                [type]="showPassword ? 'text' : 'password'"
                matInput
                placeholder="Enter your password"
                formControlName="password"
                required
              />
              <button
                type="button"
                mat-icon-button
                matSuffix
                (mousedown)="toggleShowPassword()"
                (mouseup)="toggleShowPassword()"
                (touchstart)="toggleShowPassword()"
                (touchend)="toggleShowPassword()"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="showPassword"
              >
                <mat-icon>{{
                  showPassword ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
              <mat-error>
                <msep-form-error
                  [control]="form.get('password')"
                ></msep-form-error>
              </mat-error>
              <mat-icon matIconPrefix>lock</mat-icon>
            </mat-form-field>
          </div>
          <section class="flex flex-col gap-8">
            <button
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="!form.valid || isLoading"
              [class.spinner]="isLoading"
            >
              Log in
            </button>
            <div class="flex align-center">
              <a role="link" routerLink="/forgot-password"
                >Forgot your password?</a
              >
            </div>
            <mat-divider inset></mat-divider>
            <button
              role="link"
              mat-raised-button
              color="accent"
              [routerLink]="['/get-started']"
            >
              Create new account
            </button>
          </section>
        </form>
      </mat-card-content>
      <mat-card-footer *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
